import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import classNames from 'classnames'
import Loadable from 'react-loadable'
import ReactGA from 'react-ga'

import LangProvider from 'context/Localization'
import NotFound from 'views/404'
// import * as serviceWorker from './serviceWorker'

import styles from './style.module.scss'


const Loader = ({ spinning = true, fullScreen }) => (
  <div
    className={classNames(styles.loader, {
      [styles.hidden]: !spinning,
      [styles.fullScreen]: fullScreen,
    })}
  />
)

const loadable=loader =>
    Loadable({
        loader,
        delay: false,
        loading: () => <Loader />,
    })


const ROUTES=[
    {
        path: '/',
        component: loadable(() => import('views/Landing')),
        exact: true,
    },
    {
        path: '/terms',
        component: loadable(() => import('views/Terms')),
        exact: true,
    },
    {
        path: '/privacy',
        component: loadable(() => import('views/Privacy')),
        exact: true,
    }
]

ReactGA.initialize('3085726481');
ReactGA.pageview(window.location.pathname + window.location.search);

const Router=() => {
  return (
    <BrowserRouter>
      <Switch>
        {ROUTES.map(route => (
          <Route
            path={route.path}
            component={route.component}
            key={route.path}
            exact={route.exact}
          />
        ))}
        <Route key="NotFound" component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

ReactDOM.render(
    <LangProvider>
          <Router />
    </LangProvider>,
  document.getElementById('root'),
)



// serviceWorker.register()
