import antdData from 'antd/lib/locale-provider/es_ES'
import localeData from 'react-intl/locale-data/es'

const messages = {
  "company.name": "CYBINT Linux",
  "company.distro": "CYBINT",
  "company.os": "Linux",
  "company.description": "Cyber Intelligence Platform",
  "company.longName": "Cyber Intelligence Platform",

  // Banner page text
  "banner.gitlab":"GitLab",
  "banner.cybintlab":"CYBINT Lab",


  // Landing page text
  "landing.solutions": "Solutions",
  "landing.downloads": "Downloads",
  "landing.technology": "Technology",
  "landing.documentation": "Documentation",
  "landing.research": "Research",
  "landing.contact": "Contact",
  "landing.products": "Products",
  "landing.funding": "Funding",
  "landing.services": "Services",
  "landing.sections": "Solutions",
  "landing.about": "About",
  "landing.alerts": "Alerts",
  "landing.support": "Support",
  

  // ISO Downloads

  "download.live": "Live CD",
  "download.live.primary": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, odit.",
  "download.live.secondary": "Lorem ipsum dolor sits amet consectetur adipisicing elit. Quam, odit.",

  "download.installer": "Installer CD",

  "download.xfce":"XFCE",
  "download.xfce.primary": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, odit.",
  "download.xfce.secondary": "Lorem ipsum dolor sits amet consectetur adipisicing elit. Quam, odit.",
  
  "download.gnomeshell":"Gnome Shell",
  "download.gnomeshell.primary": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, odit.",
  "download.gnomeshell.secondary": "Lorem ipsum dolor sits amet consectetur adipisicing elit. Quam, odit.",

  "download.kdeplasma":"KDE Plasma",
  "download.kdeplasma.primary": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, odit.",
  "download.kdeplasma.secondary": "Lorem ipsum dolor sits amet consectetur adipisicing elit. Quam, odit.",
  
  
  
  
    // Landing page text
    "funding.fundme": "Solutions",
    "funding.description": "CYBINT linux is a Debian-based open-source Linux distribution for data science, open source intelligence, and cyber security research.",
    "funding.cybint": "CYBINT.io",
    "funding.funding1": "Funing 1",
    "funding.funding2": "Funing 2",


  
  
  
  // Research and Development
  "rnd.lab": "CYBINT Lab",
  "rnd.tools": "Open Source Tools",
  "rnd.datasets": "Training Datasets",
  "rnd.metadata": "Metadata",
  "rnd.linguistics": "Linguistics Datasets",
  "rnd.patterns": "Pattern Datasets",
  "rnd.statistics": "Statistical Datasets",
  "rnd.automation": "Automation",
  "rnd.ai": "Artificial Intelligence",
  "rnd.orchestration": "Orchestration",
  "rnd.development": "Development",
  "rnd.repos": "Repositories",
  "rnd.packages": "Packages",
  "rnd.capabilities": "Capabilities",

  // Documentation text
  "docs.operations": "Operations",
  "docs.analysis": "Analysis",

  // Footer text
  "footer.company": "Company",
  "footer.support": "Support",
  "footer.contact": "Contact",
  "footer.privacy": "Privacy",
  "footer.terms": "Terms",
  "footer.copyrightText": "Copyright © 2020. All rights reserved",

  // Apps
  "apps.title.applications": "Applications",

  "apps.search": "Search",
  "apps.search.msg": "Searching structured data",
  "apps.breaches": "Breaches",
  "apps.breaches.msg": "View data breaches and related information",
  "apps.privacy": "Privacy",
  "apps.privacy.msg": "Check your online privacy footprint.",
  "apps.leaks": "Leaks",
  "apps.leaks.msg": "View data leaks and sources.",
  "apps.deadDrop": "DeadDrop",
  "apps.deadDrop.msg": "Anonymous Uploads",
  "apps.people": "People",
  "apps.people.msg": "Search people",
  "apps.groups": "Groups",
  "apps.groups.msg": "Search groups",
  "apps.news": "News",
  "apps.news.msg": "View historical news articles",
  "apps.files": "Files",
  "apps.files.msg": "Search online files",
  "apps.domains": "Domains",
  "apps.domains.msg": "Search domain information",
  "apps.devices": "Devices",
  "apps.devices.msg": "Search IoT device information",
  "apps.threats": "Threats",
  "apps.threats.msg": "Search threat indicators",

  "404.title": "Page not found",
  "404.msg": "The page is deprecated, deleted, or does not exist at all",
  "404.buttonText": "Go back to the home page",

  // Titlecase words
  // 'search': 'Search',
  // 'devices': 'Devices',
  // 'files': 'Files',
  // 'account': 'Account',
  // 'login': 'Login',
  // 'register': 'Register',
  // '': '',
  // '': '',
  // '': '',
  // '': '',
};
export default {
  locale: 'es-ES',
  antdData,
  localeData,
  messages,
}
