import React from 'react'
import {Link} from 'react-router-dom'
import {Result, Button, Layout} from 'antd'

// import Component from 'components/antd'
// import {Layout} from 'antd';
// import styles from './style.module.scss'

// const {Content}=Layout;

// const Layout=Component({name: 'layout'})

export default class NotFound extends React.Component {
  render() {
    return (
      <Layout>
        <div
          style={{
            minHeight: 'calc(100vh - 500px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              maxWidth: '560px',
              backgroundColor: '',
              padding: '0px 0px',
              margin: '0px auto',
              borderRadius: '10px',
              flex: '1',
            }}
          >
            <div
              style={{
                maxWidth: '400px',
                margin: '0 auto',
              }}
            >
              <Result
                status="404"
                title="Page not found"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                  <Link to="/apps/search" className="btn">
                    <Button to="/apps/search" type="primary" className="btn">
                      Go to the Search
                    </Button>
                  </Link>
                }
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
