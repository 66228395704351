import React, { useState, createContext } from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider, addLocaleData } from 'react-intl'

import english from 'locales/en-US'
import french from 'locales/fr-FR'
import russian from 'locales/ru-RU'
import chinese from 'locales/zh-CN'
import arabic from 'locales/ar-EG'
import spanish from 'locales/es-ES'
import tagalog from 'locales/tl-PH'

const locales = {
  // Default languages
  en: english,
  fr: french,
  ru: russian,
  zh: chinese,
  ar: arabic,
  es: spanish,
  tl: tagalog,

  // Country languages (Lower Case)
  'fr-fr': french,
  'es-es': spanish,
  'ru-ru': russian,
  'zh-cn': chinese,
  'en-us': english,
  'ar-eg': arabic,
  'tl-ph': tagalog,

  // Country languages
  'fr-FR': french,
  'es-ES': spanish,
  'ru-RU': russian,
  'zh-CN': chinese,
  'en-US': english,
  'ar-EG': arabic,
  'tl-PH': tagalog,
}

addLocaleData(spanish.localeData)
addLocaleData(english.localeData)
addLocaleData(french.localeData)
addLocaleData(russian.localeData)
addLocaleData(chinese.localeData)
addLocaleData(arabic.localeData)
addLocaleData(tagalog.localeData)

export const LangContext = createContext()

const LangProvider = ({ children }) => {
  const defaultLocale = navigator.language || 'en-US'
  const [language, setLang] = useState(defaultLocale)

  return (
    <LangContext.Provider value={{ language, setLang }}>
      <ConfigProvider locale={locales[language].antdData}>
        <IntlProvider locale={locales[language].locale} messages={locales[language].messages}>
          {children}
        </IntlProvider>
      </ConfigProvider>
    </LangContext.Provider>
  )
}

export default LangProvider
