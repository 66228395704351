import antdData from "antd/lib/locale-provider/en_US";
import localeData from "react-intl/locale-data/en";

const messages = {
  "company.name": "CYBINT Linux",
  "company.distro": "CYBINT",
  "company.os": "Linux",
  "company.description": "Cyber Intelligence Distribution",
  "company.longName": "Cyber Intelligence Distribution",

  // Banner page text
  "banner.gitlab": "GitLab",
  "banner.cybintlab": "CYBINT Lab",

  // Landing page text
  "landing.title": "Cyber Intelligence Distribution",
  "landing.solutions": "Solutions",
  "landing.downloads": "Downloads",
  "landing.technology": "Technology",
  "landing.documentation": "Documentation",
  "landing.research": "Research",
  "landing.contact": "Contact",
  "landing.products": "Products",
  "landing.tools": "Tools",
  
  "landing.services": "Services",
  "landing.sections": "Solutions",
  "landing.about": "About",
  "landing.alerts": "Alerts",
  "landing.support": "Support",

  // ISO Downloads

  "download.iso": "USB Live/Installer",
  "download.iso.text": "Live and installer ISO image",

  "download.installer": "Installer CD",
  "download.installer.text":
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam, odit.",

  "download.xfce": "XFCE Desktop Environment",
  "download.xfce.text":
    "Xfce is a lightweight desktop environment for UNIX-like operating systems. It aims to be fast and low on system resources, while still being visually appealing and user friendly.",

  "download.gnomeshell": "Gnome Shell Desktop",
  "download.gnomeshell.text":
    "Every part of GNOME 41 has been designed to make it simple and easy to use. The Activities Overview is a simple way to access all your basic tasks. A press of a button is all it takes to view your open windows, launch applications, or check if you have new messages. Having everything in one convenient place means you don’t have to learn your way around a maze of different technologies.",

  "download.kdeplasma": "KDE Plasma Desktop",
  "download.kdeplasma.text":
    "Plasma is made to stay out of the way as it helps you get things done. But under its light and intuitive surface, it's a powerhouse. So you're free to choose ways of usage right as you need them and when you need them.",

  "download.i3": "I3 Tiling Window Manager",
  "download.i3.text":
    "i3 is a tiling window manager, completely written from scratch. The target platforms are GNU/Linux and BSD operating systems, our code is Free and Open Source Software (FOSS) under the BSD license. i3 is primarily targeted at advanced users and developers. Based upon the experiences we made when wanting to hack/fix wmii, we agreed upon the following goals for i3: ",


  // Tools
  "tools.title": "Tools",
  "tools.description": "The CYBINT Linux distribution contains a vast array of tools and utilities for cyber security, open source intelligence and data science ",
  "tools.more": "See CYBINT Lab for all Tools ",






  // List of tools
  "tools.tool1": "Tool 1",
  "tools.tool2": "Tool 2",
  "tools.tool3": "Tool 3",



  // Landing page text
  "funding.title": "Supporters",
  "funding.fundme": "Fund Me",
  "funding.description":
    "CYBINT linux is a Debian-based open-source Linux distribution for data science, open source intelligence, and cyber security research.",
  // List of supporters
  "funding.cybint": "CYBINT.io",
  "funding.funding1": "Funing 1",
  "funding.funding2": "Funing 2",

  // Research and Development
  "rnd.lab": "CYBINT Lab",
  "rnd.tools": "Open Source Tools",
  "rnd.datasets": "Training Datasets",
  "rnd.metadata": "Metadata",
  "rnd.linguistics": "Linguistics Datasets",
  "rnd.patterns": "Pattern Datasets",
  "rnd.statistics": "Statistical Datasets",
  "rnd.automation": "Automation",
  "rnd.ai": "Artificial Intelligence",
  "rnd.orchestration": "Orchestration",
  "rnd.development": "Development",
  "rnd.repos": "Repositories",
  "rnd.packages": "Packages",
  "rnd.capabilities": "Capabilities",

  // Documentation text
  "docs.operations": "Operations",
  "docs.analysis": "Analysis",

  // Footer text
  "footer.company": "Company",
  "footer.support": "Support",
  "footer.contact": "Contact",
  "footer.privacy": "Privacy",
  "footer.terms": "Terms",
  "footer.copyrightText": "Copyright © 2020. All rights reserved",

  "404.title": "Page not found",
  "404.msg": "The page is deprecated, deleted, or does not exist at all",
  "404.buttonText": "Go back to the home page",
};

export default {
  locale: "en-US",
  antdData,
  localeData,
  messages,
};
